// App.js
import React, { useEffect, useState, useRef } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { FaRocket, FaCrown, FaBuilding, FaGithub, FaFacebook, FaEnvelope, FaInstagram } from "react-icons/fa";
import NET from 'vanta/dist/vanta.net.min';
import * as THREE from 'three';
import WaveformVisual from "./components/WaveformVisual";

import logo from './assets/images/logo.png'
import investor1 from './assets/images/investor1.png'
import investor2 from './assets/images/investor2.png'
import anomaly_detection from './assets/images/anomaly_detection.jpg'
import data_collection from './assets/images/data_collection.jpg'
import professional_forecasting from './assets/images/professional_forecasting.jpg'
import agent_flowchart_1 from './assets/images/agent_flowchart_1.png'
import agent_flowchart_2 from './assets/images/agent_flowchart_2.png'
import agent_flowchart_3 from './assets/images/agent_flowchart_3.png'


export default function AutoEdgePage() {
  const [darkMode, setDarkMode] = useState(true);
  const [stars, setStars] = useState(null);
  const [activeVideo, setActiveVideo] = useState(null);
  const [faqOpen, setFaqOpen] = useState(null);
  const modalRef = useRef(null);
  const vantaRef = useRef(null);
  const vantaEffect = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    AOS.init({ duration: 800, once: true, mirror: false });

    fetch("https://api.github.com/repos/keras-team/autokeras")
      .then(res => res.json())
      .then(data => setStars(data.stargazers_count))
      .catch(() => setStars(null));
  }, []);

  useEffect(() => {
    if (!vantaEffect.current && vantaRef.current) {
      vantaEffect.current = NET({
        el: vantaRef.current,
        THREE: THREE,
        color: 0x00ffff,
        backgroundColor: darkMode ? 0x000000 : 0xffffff,
        points: 10.0,
        maxDistance: 25.0,
        spacing: 18.0,
      });
    }
    return () => {
      if (vantaEffect.current) vantaEffect.current.destroy();
    };
  }, [darkMode]);

  const toggleTheme = () => setDarkMode(!darkMode);

  const handleOutsideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      setActiveVideo(null);
    }
  };

  useEffect(() => {
    if (activeVideo) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [activeVideo]);

  const DemoCard = ({ title, children }) => (
    <div className={`p-6 rounded-xl border ${darkMode ? "border-gray-700" : "border-gray-200"}`}>
      <h3 className="text-xl font-semibold mb-2">{title}</h3>
      <p className={`${darkMode ? "text-gray-400" : "text-gray-600"}`}>{children}</p>
    </div>
  );

  const FeatureSection = ({ title, children }) => (
    <div>
      <h2 className={`text-3xl md:text-4xl font-bold mb-4 ${darkMode ? "text-white" : "text-gray-900"}`}>{title}</h2>
      <p className={`${darkMode ? "text-gray-400" : "text-gray-600"}`}>{children}</p>
    </div>
  );

  const HowCard = ({ title, description, image, video }) => (
    <div
      className="group relative cursor-pointer transition-transform duration-300 hover:scale-[1.02]"
      onClick={() => setActiveVideo(video)}
    >
      <div className="relative z-10 rounded-lg overflow-hidden border border-transparent transition-all duration-300 group-hover:border-cyan-400 group-hover:shadow-[0_0_20px_rgba(0,255,255,0.6)]">
        <img src={image} alt={title} className="w-full h-56 object-cover" />
        <div className={`p-4 ${darkMode ? "bg-gray-800 text-white" : "bg-white text-gray-900"}`}>
          <h3 className="text-lg font-semibold text-center mb-2 transition duration-300 group-hover:text-cyan-400 group-hover:drop-shadow-[0_0_6px_rgba(0,255,255,0.8)]">
            {title}
          </h3>
          <p className={`text-sm text-center ${darkMode ? "text-gray-400" : "text-gray-600"}`}>
            {description}
          </p>
        </div>
      </div>
    </div>
  );
  

  const PricingCard = ({ title, price, features, buttonLabel, buttonLink, icon }) => (
    <div className={`rounded-xl border shadow-lg p-6 flex flex-col justify-between transform hover:scale-[1.02] transition duration-300 ${darkMode ? "border-gray-700 bg-gray-900 text-white" : "border-gray-200 bg-white text-gray-900"}`}>
      <div className="flex items-center gap-3 mb-3 text-2xl font-bold">
        {icon}
        <span>{title}</span>
      </div>
      <p className="text-lg font-semibold mb-4">{price}</p>
      <ul className="space-y-2 mb-6 text-sm">
        {features.map((f, idx) => (
          <li key={idx}>✓ {f}</li>
        ))}
      </ul>
      <a href={buttonLink} target="_blank" rel="noopener noreferrer" className={`mt-auto inline-block text-center px-5 py-2 rounded-lg font-medium transition-colors ${darkMode ? "bg-white text-black hover:bg-gray-100" : "bg-gray-900 text-white hover:bg-gray-800"}`}>{buttonLabel}</a>
    </div>
  );

  const faqItems = [
    {
      question: "How do I upgrade from Starter to Pro?",
      answer: "You can upgrade anytime from your account dashboard by selecting the 'Upgrade' option."
    },
    {
      question: "What is included in Enterprise support?",
      answer: "Enterprise plans include SLA-backed support, on-prem deployment, and dedicated assistance."
    },
    {
      question: "Can I cancel my subscription anytime?",
      answer: "Yes. Your subscription will remain active until the end of the billing cycle."
    }
  ];

  return (
    <div className={`${darkMode ? "bg-black" : "bg-white"} min-h-screen transition-colors duration-300`}>

      {/* NavBar */}
      <nav
        className={`fixed top-0 w-full z-50 transition-colors duration-300 ${
          darkMode ? "bg-black/95 border-gray-800" : "bg-white/95 border-gray-100"
        } backdrop-blur-md border-b`}
      >
        <div className="w-full px-4 sm:px-6 lg:px-8 py-4 flex items-center justify-between">
          {/* 左侧 Logo */}
          <a href="/" className="flex-shrink-0">
            <img src={logo} alt="Logo" className="h-20 w-auto" />
          </a>

          {/* 中间导航按钮（居中） */}
          <div className="absolute left-1/2 transform -translate-x-1/2 hidden md:flex gap-6 text-sm font-medium">
            <a
              href="#why-us"
              className={`${
                darkMode ? "text-white" : "text-gray-800"
              } hover:text-blue-600 transition-colors`}
            >
              Why us
            </a>
            <a
              href="#how-it-works"
              className={`${
                darkMode ? "text-white" : "text-gray-800"
              } hover:text-blue-600 transition-colors`}
            >
              How it works
            </a>
            <a
              href="#pricing"
              className={`${
                darkMode ? "text-white" : "text-gray-800"
              } hover:text-blue-600 transition-colors`}
            >
              Pricing
            </a>
            {/*
            <a
              href="#faq"
              className={`${
                darkMode ? "text-white" : "text-gray-800"
              } hover:text-blue-600 transition-colors`}
            >
              FAQs
            </a>
            <a
              href="#team-info"
              className={`${
                darkMode ? "text-white" : "text-gray-800"
              } hover:text-blue-600 transition-colors`}
            >
              Our team
            </a>
            */}
            <a
              href="#contact-us"
              className={`${
                darkMode ? "text-white" : "text-gray-800"
              } hover:text-blue-600 transition-colors`}
            >
              Contact us
            </a>
          </div>

          {/* 右侧功能 */}
          <div className="flex items-center gap-4 ml-auto">
            {/* GitHub Stars */}
            <a
              href="https://autokeras.com/"
              target="_blank"
              rel="noopener noreferrer"
              className={`${
                darkMode ? "text-white" : "text-gray-800"
              } flex items-center gap-1 text-sm hover:underline`}
            >
              ⭐ {stars !== null ? stars : "GitHub"}
            </a>

            {/* 主题切换按钮 */}
            <button
              onClick={toggleTheme}
              className={`px-4 py-2 rounded-lg font-medium transition-colors ${
                darkMode
                  ? "text-white hover:bg-white/10"
                  : "text-gray-900 hover:bg-black/10"
              }`}
            >
              {darkMode ? "☀️ Light" : "🌙 Dark"}
            </button>

            {/* Sign in 按钮 */}
            <a
              href="https://ts.autoedge.ai/login"
              className={`px-6 py-2 rounded-lg font-semibold transition-colors ${
                darkMode
                  ? "bg-blue-600 hover:bg-blue-700 text-white"
                  : "bg-blue-600 hover:bg-blue-700 text-white"
              }`}
            >
              Sign in
            </a>
          </div>
        </div>
      </nav>

      {/* 1. Hero Section */}
      <section
        className="relative flex flex-col md:flex-row justify-between items-center px-6 py-40 md:py-60 overflow-hidden"
        data-aos="fade-up"
      >
        <div className="absolute inset-0 z-0 pointer-events-none flex justify-center items-center">
          <WaveformVisual />
        </div>
        <div className="text-left md:w-2/5 mb-10 md:mb-0 z-10">
          <h1
            className={`text-4xl sm:text-5xl lg:text-6xl font-bold mb-6 ${
              darkMode ? "text-white" : "text-gray-900"
            }`}
          >
            AI-powered time series intelligence
          </h1>
          <p className="text-lg md:text-3xl mb-8 text-gray-400">
            AutoEdge leverages advanced AI to collect, analyze, and interpret time
            series data in real time. Detect anomalies, generate insights, and
            forecast the future — all in one intelligent platform.
          </p>
          <p className="text-sm text-gray-400 uppercase mb-4">Backed by</p>
          <div className="flex gap-4 mb-12">
            <img src={investor1} alt="Investor 1" className="h-12" />
            <img src={investor2} alt="Investor 2" className="h-12" />
          </div>
          <a
            href="https://ts.autoedge.ai/login"
            className="inline-block bg-white text-black font-semibold py-3 px-6 rounded-lg hover:bg-gray-200 transition"
          >
            Try It Now
          </a>
        </div>

        {/* Remove the side waveform container now */}
      </section>


      {/* 2. Why AutoEdge Section */}
      <section id="why-us" className="scroll-mt-32 py-24 px-4 sm:px-6 lg:px-8" data-aos="fade-up">
        <div className="max-w-5xl mx-auto text-center">
          <h2 className={`text-3xl md:text-4xl font-bold mb-6 ${darkMode ? "text-white" : "text-gray-900"}`}>
            Why AutoEdge
          </h2>
          <div className={`text-lg space-y-4 ${darkMode ? "text-gray-400" : "text-gray-600"}`}>
            <p>
              AutoEdge empowers teams to uncover real-time insights from time series data — no complex setup, no data leaks.
            </p>
            <p>
              Our AI-driven platform automatically detects anomalies, forecasts trends, and adapts seamlessly to your environment.
              With built-in forecasting, you gain clarity and confidence — spotting issues before they impact your operations.
            </p>
          </div>
        </div>
      </section>

      {/* 3. How it Works Section */}
      <section id="how-it-works" className="scroll-mt-32 py-20 px-4 sm:px-6 lg:px-8" data-aos="fade-up">
        <div className="max-w-7xl mx-auto">
          <h2 className={`text-3xl md:text-4xl font-bold text-center mb-12 ${darkMode ? "text-white" : "text-gray-900"}`}>How it works</h2>
          <div className="relative flex flex-col md:flex-row items-center justify-between gap-8">
            <HowCard
              title="Data Collection"
              description="We install data-collection devices and begin streaming sensor data to our cloud."
              image={data_collection}
              video="/anomaly_detection.mp4"
            />
            <div className="hidden md:block text-3xl text-gray-400 dark:text-gray-500">
              →
            </div>
            <HowCard
              title="Anomaly Detection"
              description="Our AI detects unusual patterns and visualizes the results for actionable insights."
              image={anomaly_detection}
              video="/anomaly_detection.mp4"
            />
            <div className="hidden md:block text-3xl text-gray-400 dark:text-gray-500">
              →
            </div>
            <HowCard
              title="Professional Forecasting"
              description="We predict future anomalies and suggest preventive actions based on trends."
              image={professional_forecasting}
              video="/professional_forecasting.mp4"
            />
          </div>
        </div>
      </section>

      {/* Video Modal */}
      {activeVideo && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/70 backdrop-blur-sm">
          <div ref={modalRef} className="bg-white rounded-lg border border-blue-500 max-w-4xl w-full mx-4 overflow-hidden">
            <video src={activeVideo} autoPlay controls className="w-full h-auto" />
          </div>
        </div>
      )}

      {/* 3.5 Agent Workflows Section */}
      <section className="py-24 px-4 sm:px-6 lg:px-8" data-aos="fade-up">
        <div className="max-w-7xl mx-auto">
          <h2 className={`text-3xl md:text-4xl font-bold text-center mb-12 ${darkMode ? "text-white" : "text-gray-900"}`}>
            Agent Workflows
          </h2>
          <div className="grid gap-8 md:grid-cols-3">
            {/* Card 1 */}
            <div
              className={`p-6 rounded-xl border shadow-md transition transform hover:scale-[1.02] hover:shadow-xl cursor-pointer ${
                darkMode ? "bg-gray-900 border-gray-700" : "bg-white border-gray-200"
              }`}
              onClick={() =>
                setSelectedImage({ src: agent_flowchart_1, title: "Easy to Get Report" })
              }
            >
              <h3 className={`text-lg font-semibold mb-4 text-center transition duration-300 ${darkMode ? "text-white" : "text-gray-900"}`}>
                Easy to Get Report
              </h3>
              <img src={agent_flowchart_1} alt="Agent Workflow 1" className="w-full rounded-md" />
            </div>

            {/* Card 2 */}
            <div
              className={`p-6 rounded-xl border shadow-md transition transform hover:scale-[1.02] hover:shadow-xl cursor-pointer ${
                darkMode ? "bg-gray-900 border-gray-700" : "bg-white border-gray-200"
              }`}
              onClick={() =>
                setSelectedImage({ src: agent_flowchart_2, title: "Make a Chart Smartly" })
              }
            >
              <h3 className={`text-lg font-semibold mb-4 text-center transition duration-300 ${darkMode ? "text-white" : "text-gray-900"}`}>
              Make a Chart Smartly
              </h3>
              <img src={agent_flowchart_2} alt="Agent Workflow 2" className="w-full rounded-md" />
            </div>

            {/* Card 3 */}
            <div
              className={`p-6 rounded-xl border shadow-md transition transform hover:scale-[1.02] hover:shadow-xl cursor-pointer ${
                darkMode ? "bg-gray-900 border-gray-700" : "bg-white border-gray-200"
              }`}
              onClick={() =>
                setSelectedImage({ src: agent_flowchart_3, title: "Efficient Chat Via Agents" })
              }
            >
              <h3 className={`text-lg font-semibold mb-4 text-center transition duration-300 ${darkMode ? "text-white" : "text-gray-900"}`}>
                Efficient Chat Via Agents 
              </h3>
              <img src={agent_flowchart_3} alt="Agent Workflow 3" className="w-full rounded-md" />
            </div>
          </div>
        </div>
      </section>


      {/* Modal for enlarged image */}
      {selectedImage && (
        <div
          className="fixed inset-0 bg-black/70 backdrop-blur-sm flex items-center justify-center z-50"
          onClick={() => setSelectedImage(null)}
        >
          <div className="relative bg-white dark:bg-gray-900 rounded-lg max-w-4xl w-full mx-4 p-4">
            <img src={selectedImage.src} alt={selectedImage.title} className="w-full h-auto rounded" />
            <p className="text-center text-sm mt-2 text-gray-600 dark:text-gray-300">
              {selectedImage.title} — Click anywhere to close
            </p>
          </div>
        </div>
      )}


      
      {/* 4. Feature Grid */}
      <section className="py-20 px-4 sm:px-6 lg:px-8" data-aos="fade-up">
        <div className="max-w-7xl mx-auto grid md:grid-cols-2 gap-12">
          <FeatureSection title="Works with Any Data Source">
          Compatible with sensors, APIs, databases, and more. Seamlessly integrates for real-time ingestion.
          </FeatureSection>
          <FeatureSection title="No Data Stored by Default">
          Your raw data stays in your system. We process it in memory and discard it when analysis is done.
          </FeatureSection>
          <FeatureSection title="AI-Powered Privacy">
          Every detection is encrypted. AutoEdge ensures your insights stay secure and confidential.
          </FeatureSection>
          <FeatureSection title="No Configuration Needed">
          Plug in your data and go. AutoEdge auto-adapts to your environment — no setup, no hassle.
          </FeatureSection>
        </div>
      </section>

      {/* 5. Pricing Section */}
      <section id="pricing" className="scroll-mt-32 py-24 px-4 sm:px-6 lg:px-8" data-aos="fade-up">
        <div className="max-w-7xl mx-auto">
          <h2
            className={`text-3xl md:text-4xl font-bold text-center mb-12 ${
              darkMode ? "text-white" : "text-gray-900"
            }`}
          >
            Pricing
          </h2>
          <h2
            className={`text-3xl md:text-4xl font-bold text-center mb-12 ${
              darkMode ? "text-white" : "text-gray-900"
            }`}
          >
            From open source to enterprise, we have a plan that fits your needs
          </h2>
          <div className="grid gap-8 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
            <PricingCard
              title="Starter"
              price="Free"
              features={[
                "Full library access",
                "Self-hosted version",
                "All core features",
                "MIT Licence"
              ]}
              buttonLabel="View on Github"
              buttonLink="https://github.com/keras-team/autokeras"
              icon={<FaRocket className="text-blue-500" />}
            />
            <PricingCard
              title="Pro"
              price="$220/mo"
              features={[
                "Everything in Open Source",
                "Priority support",
                "Includes 30 USD of API credits per month",
                "Unlimited access"
              ]}
              buttonLabel="Get Started"
              buttonLink="https://ts.autoedge.ai/login"
              icon={<FaCrown className="text-yellow-500" />}
            />
            <PricingCard
              title="Enterprise"
              price="Contact Us"
              features={[
                "Dedicated support team",
                "SLA guarantees",
                "On-premise deployment",
                "Custom integrations"
              ]}
              buttonLabel="Contact Us"
              buttonLink="#"
              icon={<FaBuilding className="text-green-500" />}
            />
          </div>
        </div>
      </section>

      {/* 6. FAQ Section */}
      <section id="faq" className="scroll-mt-32 py-24 px-4 sm:px-6 lg:px-8" data-aos="fade-up">
        <div className="max-w-7xl mx-auto">
          <div className="max-w-3xl mx-auto">
            <h3 className={`text-3xl font-bold mb-8 text-center ${darkMode ? "text-white" : "text-gray-900"}`}>
              FAQs
            </h3>
            <div className="space-y-6">
              {faqItems.map((faq, idx) => (
                <div key={idx} className="border-b pb-4">
                  <button
                    onClick={() => setFaqOpen(faqOpen === idx ? null : idx)}
                    className={`w-full text-left font-medium ${darkMode ? "text-white" : "text-gray-900"}`}
                  >
                    {faq.question}
                  </button>
                  {faqOpen === idx && (
                    <p className={`mt-2 text-sm ${darkMode ? "text-gray-300" : "text-gray-600"}`}>
                      {faq.answer}
                    </p>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* 7. CTA Section */}
      <section className="py-20 px-4 sm:px-6 lg:px-8 text-center" data-aos="fade-up">
        <div className="max-w-4xl mx-auto">
          <h2 className={`text-3xl lg:text-4xl font-bold mb-6 ${darkMode ? "text-white" : "text-gray-900"}`}>
          Start detecting anomalies today
          </h2>
          <p className={`text-lg mb-8 ${darkMode ? "text-gray-400" : "text-gray-600"} mx-auto`}>
          From ingestion to insight in under a minute. AI-powered, cloud-ready, and designed for speed.
          </p>
          <a
            href="https://ts.autoedge.ai/login"
            className={`inline-block px-8 py-3.5 rounded-lg font-semibold transition-transform hover:scale-[1.02] ${
              darkMode
                ? "bg-white text-black hover:bg-gray-100"
                : "bg-gray-900 text-white hover:bg-gray-800"
            }`}
          >
            Try It Now
          </a>
        </div>
      </section>

      {/* 8. Our Team Section */}
      {/* <section id="team-info" className="scroll-mt-32 py-24 px-4 sm:px-6 lg:px-8" data-aos="fade-up">
        <div className={`rounded-xl px-8 py-12 shadow-lg max-w-6xl mx-auto ${darkMode ? "bg-gray-900 text-white" : "bg-gray-100 text-gray-900"}`}>
          <h2 className="text-3xl md:text-4xl font-bold mb-12 text-left">Our Team</h2>
          <div className="grid gap-12 sm:grid-cols-1 md:grid-cols-2">
            <div className="flex flex-col items-start text-left">
              <img src="/logo.png" alt="Xia Ben Hu" className="w-40 h-40 rounded-full object-cover mb-4" />
              <h3 className="text-xl font-semibold mb-1">Xia Ben Hu</h3>
              <p className="text-sm text-gray-400">Co-founder of AutoEdge</p>
            </div>
            <div className="flex flex-col items-start text-left">
              <img src="/logo.png" alt="Alfredo Costilla Reyes" className="w-40 h-40 rounded-full object-cover mb-4" />
              <h3 className="text-xl font-semibold mb-1">Alfredo Costilla Reyes</h3>
              <p className="text-sm text-gray-400">CEO of AutoEdge</p>
            </div>
          </div>
        </div>
      </section> */}
      
      {/* 9. Support Section */}
      <section id="contact-us" className="bg-gray-50 dark:bg-gray-900 scroll-mt-32 py-20 px-4 sm:px-6 lg:px-8" data-aos="fade-up">
        <div className="max-w-7xl mx-auto text-center">
          <h2 className="text-3xl lg:text-4xl font-bold mb-4 text-primary dark:text-white">Support</h2>
          <p className="text-lg mb-8 text-gray-600 dark:text-gray-300 max-w-2xl mx-auto">
            Need help? Browse our documentation, reach out to our team, or check the community for answers.
          </p>
          <div className="grid md:grid-cols-3 gap-6 text-left">
            <div className="p-6 rounded-lg bg-white dark:bg-gray-800 border dark:border-gray-700">
              <h3 className="text-lg font-semibold text-primary dark:text-white mb-2">Help Center</h3>
              <p className="text-gray-600 dark:text-gray-400 text-sm">
                Get quick answers to common questions.
              </p>
              <a href="#" className="mt-4 inline-block text-blue-600 dark:text-blue-400 font-medium hover:underline">Visit Help Center →</a>
            </div>
            <div className="p-6 rounded-lg bg-white dark:bg-gray-800 border dark:border-gray-700">
              <h3 className="text-lg font-semibold text-primary dark:text-white mb-2">Live Chat</h3>
              <p className="text-gray-600 dark:text-gray-400 text-sm">
                Talk with our support team in real time.
              </p>
              <a href="#" className="mt-4 inline-block text-blue-600 dark:text-blue-400 font-medium hover:underline">Start Chat →</a>
            </div>
            <div className="p-6 rounded-lg bg-white dark:bg-gray-800 border dark:border-gray-700">
              <h3 className="text-lg font-semibold text-primary dark:text-white mb-2">Contact Us</h3>
              <p className="text-gray-600 dark:text-gray-400 text-sm">
                Send us an email and we’ll reply within 24 hours.
              </p>
              <a href="mailto:alfredo@autoedge.ai" className="mt-4 inline-block text-blue-600 dark:text-blue-400 font-medium hover:underline">Email Us →</a>
            </div>
          </div>
        </div>
      </section>

      {/* 10. Socials Section */}
      <section className="scroll-mt-32 py-16 px-4 sm:px-6 lg:px-8 bg-gray-50 dark:bg-gray-900" data-aos="fade-up">
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-3xl font-bold mb-6 text-gray-800 dark:text-white">Connect with Us</h2>
          <div className="flex justify-center space-x-6 text-3xl">
            <a href="https://autokeras.com/" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-black dark:text-gray-300 dark:hover:text-white transition">
              <FaGithub />
            </a>
            <a href="mailto:alfredo@autoedge.ai" className="text-gray-600 hover:text-black dark:text-gray-300 dark:hover:text-white transition">
              <FaEnvelope />
            </a>
            <a href="https://facebook.com/your-org" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-black dark:text-gray-300 dark:hover:text-white transition">
              <FaFacebook />
            </a>
            <a
              href="https://instagram.com/your-org"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-600 hover:text-black dark:text-gray-300 dark:hover:text-white transition"
            >
              <FaInstagram />
            </a>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className={`${darkMode ? "bg-gray-900" : "bg-gray-50"} pt-16 pb-12`}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <p className={`${darkMode ? "text-gray-400" : "text-gray-600"} mb-4`}>
            &copy; {new Date().getFullYear()} AutoEdge. All rights reserved.
          </p>
          <div className="flex justify-center space-x-6">
            <a href="#" className={`${darkMode ? "text-gray-400 hover:text-white" : "text-gray-600 hover:text-gray-900"} transition-colors`}>
              Privacy Policy
            </a>
            <a href="#" className={`${darkMode ? "text-gray-400 hover:text-white" : "text-gray-600 hover:text-gray-900"} transition-colors`}>
              Terms of Service
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
}
