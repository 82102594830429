import React, { useEffect, useRef } from "react";

export default function WaveformVisual() {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    let width = canvas.width = canvas.offsetWidth;
    let height = canvas.height = canvas.offsetHeight;

    const gradient = ctx.createLinearGradient(0, 0, width, 0);
    gradient.addColorStop(0, "rgba(0, 255, 255, 0)"); // fade on the left
    gradient.addColorStop(0.3, "rgba(0, 255, 255, 0.6)");
    gradient.addColorStop(1, "rgba(0, 255, 255, 1)");

    let time = 0;
    const frequency = 0.015;
    const damping = 0.005;

    function draw() {
      ctx.clearRect(0, 0, width, height);
      ctx.beginPath();

      for (let x = 0; x < width; x++) {
        const t = time * 0.9 + x * 0.016;
      
        const baseWave =
          Math.sin(t * 0.9) * 1.0 +
          Math.sin(t * 0.15 + 10) * 1.4 +
          Math.sin(t * 0.4 + 30) * 1.1 +
          Math.sin(t * 0.75 + 50) * 0.9 +
          Math.sin(t * 0.23 + 80) * 1.2;
      
        const fade = Math.min(1, x / 180);
        const y = height / 2 + baseWave * 30 * fade;
      
        ctx.lineTo(x, y);
      }
      
      ctx.strokeStyle = gradient;
      ctx.lineWidth = 2;
      ctx.shadowColor = "#00ffff";
      ctx.shadowBlur = 10;
      ctx.stroke();

      time += 0.05;
      requestAnimationFrame(draw);
    }

    draw();

    const handleResize = () => {
      width = canvas.width = canvas.offsetWidth;
      height = canvas.height = canvas.offsetHeight;
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <canvas
      ref={canvasRef}
      className="w-full h-full absolute top-0 left-0"
      style={{ pointerEvents: "none" }}
    />
  );
}
